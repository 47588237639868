import { setUser } from '@sentry/vue'

export default defineNuxtRouteMiddleware(() => {
  const { isAuthenticated, user } = useAuth()
  const config = useRuntimeConfig()

  if (!isAuthenticated.value) {
    if (process.env.NODE_ENV === 'production') {
      setUser(null)
    }

    return navigateTo(config.public.api.redirects.onAuthOnly, { replace: true })
  }

  if (process.env.NODE_ENV === 'production') {
    setUser({
      id: user.value?.id,
      email: user.value?.email,
    })
  }
})
